import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import Directories from "../components/Home/Directories";
import FormHome from "../components/Home/FormHome";
import Map from "../components/Contact/MapContent";
import BaseLayout from "../components/global/BaseLayout";
import CounterUp from "../components/global/CounterUp";
import ServicesHome from "../components/Home/ContentServicesHome";
import HeroSection from "../components/Home/HeroSection";
import Paletacolor from "../components/global/Paletacolor";
import BlockPrincipal from "../components/block/Block_swiper";
import BlockSecondary from "../components/block/Block_7";
import BlockAbout from "../components/block/Block_71";
import SlideBlock from "../components/block/SlideLogoForm";
import VideoPromocional from "../components/global/VideoPromocional";
import ContentServices from "../components/Services/ContentServices";
import GalleryContent from "../components/Gallery/GalleryContent";
import ValuesOne from "../components/values/Values_2";



function Home() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Home">
      <div className="md:max-w-full w-full">
        {/* <Modal /> */}
        <HeroSection
          title={rpdata?.dbSlogan?.[4]?.slogan}
          texts={rpdata?.dbValues?.[0].description}
          urlVideo={"https://firebasestorage.googleapis.com/v0/b/videostock-8dab7.appspot.com/o/video%2FRemodeling%20Stock.mp4?alt=media&token=e637df1a-f5e0-4b66-84de-2001506fd3f9"}
          bgImg={rpdata?.gallery?.[1]}
          bgImg1={rpdata?.gallery?.[5]}
          bgImg2={rpdata?.gallery?.[2]}
          bgImg3={rpdata?.gallery?.[3]}
        />
        <div class="max-w-5xl mx-auto bg1 shadow-md rounded-lg overflow-hidden mt-10 p-6">
          <div class="bg-gray-100 p-4 border-l-4 border-primary">
            <p class="text-xl md:text-4xl text-gray-800 font-semibold">
              "Whatever you do, work at it with all your heart, as working for the Lord, not for human masters, since you know that you will receive an inheritance from the Lord as a reward. It is the Lord Christ you are serving."
            </p>
            <p class="text-right text-gray-600 text-lg md:text-xl mt-4 italic">- Colossians 3:23-24</p>
          </div>
        </div>

        <BlockPrincipal
          title={rpdata?.dbSlogan?.[1]?.slogan}
          text={rpdata?.dbHome?.[0].text}
          image={rpdata?.gallery?.[0]}
          corte={rpdata?.gallery}
          sloganPrincipal={true}
          listServices={true}
        />
        {/* video promocional */}
        {
          rpdata?.videosPromo?.[0]?.activo ?
            <VideoPromocional
              title={rpdata?.dbSlogan?.[2]?.slogan}
              text={rpdata?.dbAbout?.[1]?.text}
              linkVideo={`${rpdata?.videosPromo?.[0].link}`}
              image={`${rpdata?.videosPromo?.[0].img}`}
              vimeoVideo
            />
            : null

        }
        <SlideBlock
          text={rpdata?.dbValues?.[2].description}
          image={rpdata?.gallery?.[5]}
        />
        <Directories />
        <BlockAbout
          title={'a little about us'}
          text={rpdata?.dbAbout?.[0]?.text}
          image={rpdata?.gallery?.[7]}
          listsAbout={true}
        />
        <ValuesOne image1={rpdata?.gallery?.[8]} />
        <CounterUp image={rpdata?.gallery?.[6]} />
        <BlockSecondary
          title={rpdata?.dbSlogan?.[3]?.slogan}
          text={rpdata?.dbHome?.[1]?.text}
          image1={rpdata?.gallery?.[1]}
          image2={rpdata?.gallery?.[2]}
          image3={rpdata?.gallery?.[3]}
        />
        {/* our reviews */}
        {
          rpdata?.reviews?.isHomeOnly === true ?
            <div className="w-4/5 mx-auto py-[100px]">
              <h1 className="pb-10 text-center">{rpdata?.labels?.general?.titleReviews}</h1>
              <div className={`${rpdata?.reviews?.links?.[0]}`}></div>
            </div>
            : null
        }
        {/* <CustomReviews/> */}
        {/* Servicios del home */}
        {
          rpdata?.simpleWidgets?.[3]?.activo ?
            <div className="pt-20">
              <ContentServices />
            </div>
            :
            <ServicesHome />
        }
        {/* Paleta de Colores */}
        {
          rpdata?.simpleWidgets?.[3]?.activo ?
            <div className="pt-10 pb-28">
              <h2 className="text-center pb-[50px]">{rpdata?.labels?.general?.titleGallery}</h2>
              <GalleryContent galleryImages={rpdata?.stock?.length > 0 ? rpdata?.stock : rpdata?.stock} />
            </div>
            :
            null
        }
        {
          rpdata?.brandingExtra?.[0]?.activo ?
            <Paletacolor />
            : null
        }
        <div
          className="bgFormHome"
          style={{ backgroundImage: `url("${rpdata?.gallery?.[5]}")` }}
        >
          <div className="relative">
            <FormHome />
          </div>
        </div>
        <Map />
      </div>
    </BaseLayout>
  );
}

export default Home;
